import {
  Activity,
  Additem,
  Bank,
  Book,
  Bookmark2,
  BuyCrypto,
  Category,
  ChartSquare,
  Convertshape2,
  EmptyWalletAdd,
  Folder,
  Grid6,
  InfoCircle,
  MenuBoard,
  Note,
  Paperclip,
  People,
  Receipt2,
  ReceiptItem,
  Save2,
  Setting,
  Shop,
  Tag2,
  Trade,
  TruckTick,
  UserCirlceAdd,
  WalletAdd,
  Receipt1,
  Message2,
  Archive,
} from "iconsax-react";
import { useQuery } from "react-query";
import { useMemo } from "react";
import { useRouter } from "next/router";

import { PermissionType, queryVars, usePermissions } from "@/hooks/utils";
import { Item } from "./sidebarItem";
import { PendingListType } from "@/sample/queries";
import { authGetter } from "@/utils/utils";

const MARKET_MANAGEMENT_PERMISSIONS: Array<PermissionType> = [
  "CAN_CROSS_TRADE",
  "CAN_VIEW_CASH_ADVANCE",
  "CAN_VIEW_DISPATCH_NAME",
  "CAN_VIEW_INVOICE",
  "CAN_VIEW_OTC_TRADES",
  "CAN_VIEW_WITHDRAWAL_REQUEST",
  "CAN_VIEW_TRANSACTION_BANKS",
  // "CAN_VIEW_WAREHOUSE_RECEIPT",
];

const MARKET_ACCESS_PERMISSIONS: Array<PermissionType> = [
  "CAN_VIEW_USER",
  "CAN_MANAGE_OMS_PROVIDER",
  "CAN_VIEW_BOARD",
  "CAN_VIEW_SECURITY",
];

const REPORTS_PERMISSIONS: Array<PermissionType> = [
  "CAN_VIEW_CLIENT_SECURITY_POSITION",
  "CAN_VIEW_OTC_TRADES",
  "CAN_FUND_CLIENT",
];

export function useSidebarItems(active): Array<Item> {
  const { keys, query } = queryVars;
  const { checkPermission } = usePermissions();
  const { pathname } = useRouter();

  function isBasePath(base: string) {
    if (active === "") return pathname.startsWith(base);
    return false;
  }

  const { data: pendingListData, isLoading: pendingListLoading } = useQuery<
    PendingListType,
    "object"
  >(keys.pendingList, () => authGetter(query.pendingList));

  const {
    pending_cash_advance,
    pending_dispatch_request,
    pending_client_invoice,
    pending_withdrawal_request,
    pending_ewr,
    pending_otc_contract,
    pending_notification,
    pending_listing,
    pending_series,
  } = useMemo(() => ({ ...pendingListData?.data }), [pendingListLoading]);

  return [
    {
      text: "Dashboard",
      icon: <Category />,
      active: true,
      link: "/",
      collapsed: false,
      visible: true,
    },
    {
      text: "Client",
      icon: <UserCirlceAdd />,
      active: false,
      link: "/client",
      collapsed: false,
      visible: checkPermission("CAN_VIEW_CLIENT"),
    },
    {
      text: "Market Management",
      icon: <Shop />,
      active: false,
      collapsed:
        active === "Market Management" || isBasePath("/market-management"),
      link: "/market-management",
      visible: MARKET_MANAGEMENT_PERMISSIONS.some(checkPermission),
      children: [
        {
          text: "Cross Trade",
          icon: <Convertshape2 />,
          active: false,
          link: "/market-management/cross-trade",
          collapsed: false,
          visible: checkPermission("CAN_CROSS_TRADE"),
        },
        {
          text: "Cash Advance",
          icon: <Additem />,
          active: false,
          link: "/market-management/cash-advance",
          visible: checkPermission("CAN_VIEW_CASH_ADVANCE"),
          collapsed: false,
          pending: pending_cash_advance,
        },
        {
          text: "Dispatch",
          icon: <TruckTick />,
          active: false,
          link: "/market-management/dispatch",
          visible: checkPermission("CAN_VIEW_DISPATCH_NAME"),
          collapsed: false,
          pending: pending_dispatch_request,
        },
        {
          text: "Invoice",
          icon: <Book />,
          active: false,
          link: "/market-management/invoice",
          visible: checkPermission("CAN_VIEW_INVOICE"),
          collapsed: false,
          pending: pending_client_invoice,
        },
        {
          text: "OTC Contracts",
          icon: <Tag2 />,
          active: false,
          link: "/market-management/otc-contracts",
          visible: checkPermission("CAN_VIEW_OTC_TRADES"),
          collapsed: false,
          pending: pending_otc_contract,
        },
        {
          text: "Derivative Contracts",
          icon: <Archive variant="Bulk" />,
          active: false,
          link: "/market-management/otc-forwards-contracts",
          visible: checkPermission("CAN_VIEW_SECURITY"),
          collapsed: false,
        },
        {
          text: "Withdrawal Request",
          icon: <EmptyWalletAdd />,
          active: false,
          link: "/market-management/withdrawal-request",
          visible: checkPermission("CAN_VIEW_WITHDRAWAL_REQUEST"),
          collapsed: false,
          pending: pending_withdrawal_request,
        },
        {
          text: "Transaction Banks",
          icon: <Bank />,
          active: false,
          link: "/market-management/transaction-banks",
          visible: checkPermission("CAN_VIEW_TRANSACTION_BANKS"),
          collapsed: false,
        },
        {
          text: "Suspense Positions",
          icon: <Activity variant="Bulk" />,
          active: false,
          link: "/market-management/suspense-positions",
          collapsed: false,
          visible: checkPermission("CAN_AUDIT_CLIENT"),
        },
        {
          text: "Warehouse Receipt",
          icon: <Receipt1 />,
          active: false,
          link: "/market-management/warehouse-receipt",
          visible: checkPermission("CAN_VIEW_WAREHOUSE"),
          collapsed: false,
          pending: pending_ewr,
        },
        {
          text: "Stock and Cash Cover",
          icon: <Message2 variant="Bulk" />,
          active: false,
          link: "/market-management/stock-and-cash-cover",
          collapsed: false,
          visible: checkPermission("CAN_VIEW_STOCK_AND_CASH_COVER_REPORT"),
        },
      ],
    },
    {
      text: "Reports",
      icon: <Folder />,
      active: false,
      link: "/reports",
      collapsed: active === "Reports" || isBasePath("/reports"),
      visible: REPORTS_PERMISSIONS.some(checkPermission),
      children: [
        {
          text: "Order Books",
          icon: <Bookmark2 />,
          active: false,
          link: "/reports/order-books",
          collapsed: false,
          visible: true,
        },
        {
          text: "Error Status",
          icon: <InfoCircle variant="Bulk" />,
          active: false,
          link: "/reports/error-status",
          collapsed: false,
          visible: true,
        },
        {
          text: "Activity Stream",
          icon: <Note variant="Bulk" />,
          active: false,
          link: "/reports/activity-stream",
          collapsed: false,
          visible: true,
        },
        {
          text: "Client Funding Log",
          icon: <WalletAdd />,
          active: false,
          link: "/reports/client-funding-log",
          collapsed: false,
          visible: checkPermission("CAN_FUND_CLIENT"),
        },
        {
          text: "Open Trades",
          icon: <Paperclip />,
          active: false,
          link: "/reports/open-trades",
          collapsed: false,
          visible: true,
        },
        {
          text: "Matched Trades",
          icon: <Trade variant="Bulk" />,
          active: false,
          link: "/reports/matched-trades",
          collapsed: false,
          visible: true,
        },
        {
          text: "Client Security Positions",
          icon: <BuyCrypto variant="Bulk" />,
          active: false,
          link: "/reports/client-security-positions",
          collapsed: false,
          visible: checkPermission("CAN_VIEW_CLIENT_SECURITY_POSITION"),
        },
      ],
    },
    {
      text: "Accounting",
      icon: <ReceiptItem />,
      active: false,
      link: "/accounting",
      collapsed: active === "Accounting" || isBasePath("/accounting"),
      visible: true,
      children: [
        {
          text: "Journal Entry",
          icon: <Save2 />,
          active: false,
          link: "/accounting/journal-entry",
          collapsed: false,
          visible: checkPermission("CAN_VIEW_MANUAL_POSTINGS_HISTORY"),
        },
        {
          text: "Accounting Reports",
          icon: <Book />,
          active: false,
          link: "/accounting/accounting-reports",
          collapsed: false,
          visible: true,
        },
        {
          text: "Transaction Mapping",
          icon: <Trade />,
          active: false,
          link: "/accounting/transaction-mapping",
          collapsed: false,
          visible: true,
        },
        {
          text: "Charts of Accounts",
          icon: <ChartSquare />,
          active: false,
          link: "/accounting/charts-of-accounts",
          collapsed: false,
          visible: true,
        },
      ],
    },
    {
      text: "Market Access",
      icon: <Setting variant="Bulk" />,
      active: false,
      link: "/market-access",
      collapsed: active === "Market Access" || isBasePath("/market-access"),
      visible: MARKET_ACCESS_PERMISSIONS.some(checkPermission),
      children: [
        {
          text: "Manage Users",
          icon: <People variant="Bulk" />,
          active: false,
          link: "/market-access/manage-users",
          collapsed: false,
          visible: checkPermission("CAN_VIEW_USER"),
        },
        {
          text: "OMS",
          icon: <Grid6 variant="Bulk" />,
          active: false,
          link: "/market-access/oms",
          collapsed: false,
          visible: checkPermission("CAN_MANAGE_OMS_PROVIDER"),
        },
        {
          text: "Boards",
          icon: <MenuBoard variant="Bulk" />,
          active: false,
          link: "/market-access/boards",
          collapsed: false,
          visible: checkPermission("CAN_VIEW_BOARD"),
        },
        {
          text: "Securities",
          icon: <Receipt2 variant="Bulk" />,
          active: false,
          link: "/market-access/securities",
          collapsed: false,
          visible: checkPermission("CAN_VIEW_SECURITY"),
        },
      ],
    },
  ];
}
