import { showNotification } from "@mantine/notifications";
import { setCookie } from "cookies-next";

import { clearErrorMessage } from "@/hooks/utils";
import { LoginType } from "@/sample/queries";
import { encrypt } from "@/utils/lib";
import { authAxios } from "@/utils/utils";

type OnLoginSuccess = {
  setShowSuccessNotification: (value: boolean) => void;
  closeModal?: () => void;
};

export function onLoginSuccess({
  setShowSuccessNotification,
  closeModal,
}: OnLoginSuccess) {
  return function onSuccess(payload?: LoginType) {
    const { data } = { ...payload };
    const { responseCode, firstname, lastname, username, token } = { ...data };

    if (responseCode === "100") {
      const userBio = JSON.stringify({
        firstname,
        lastname,
        username,
      });

      setShowSuccessNotification(true);

      if (!closeModal) {
        localStorage.setItem("ecn.user.bio", userBio);

        const permissions = JSON.stringify(data?.permissions ?? []);
        localStorage.setItem("ecn.user.permissions", encrypt(permissions));

        const lastPage =
          sessionStorage.getItem("ecn.user.last.page") ?? "/client";

        const pages = {
          [lastPage]: lastPage,
          "/": "/client",
        };

        authAxios.defaults.headers.common.Authorization = `Bearer ${token}`;
        setCookie("ecn.client.auth.token", token, {
          maxAge: 60 * 60 * 24,
          path: "/",
        });

        try {
          history.pushState({}, "", pages[lastPage]);
          location.reload();
        } catch (error) {
          if (process.env.NODE_ENV === "development") {
            console.error(error);
          }
        }
      }
    } else {
      const { message = "Operation failed", errors } = { ...payload };

      showNotification({
        title: message,
        message: (
          <pre className="max-h-full overflow-auto whitespace-pre-line">
            {clearErrorMessage(errors)}
          </pre>
        ),
        color: "red",
      });
    }
  };
}
