import { AxiosRequestHeaders } from "axios";
import { Button, PasswordInput, TextInput, clsx } from "@mantine/core";
import { useMutation } from "react-query";
import { showNotification } from "@mantine/notifications";
import { useForm, yupResolver } from "@mantine/form";
import { ReactNode, useCallback } from "react";

import { Authenticate } from "@/sample/mutations";
import { QueryVarsType, clearErrorMessage, queryVars } from "@/hooks/utils";
import { authPoster } from "@/utils/utils";

import * as Yup from "yup";

interface ISignIn {
  onSettled(e?: any): void;
  url: Extract<keyof QueryVarsType["query"], string>;
  children?: ReactNode;
  headers?: AxiosRequestHeaders;
  className?: string;
}

export function SignIn({
  onSettled,
  url = "authenticate",
  className,
  children,
}: ISignIn) {
  const userFn = useCallback(
    (data) => {
      return authPoster(queryVars.query[url] as string, data).catch(
        (response) => {
          showNotification({
            title: "Operation failed",
            message: response.message,
            color: "accent.8",
          });
        }
      );
    },
    [url]
  );

  const { mutate: userLogin, isLoading: userLoading } =
    useMutation<Authenticate>(userFn, {
      onSettled,
      onSuccess(data) {
        if (data?.responseCode === "103") {
          showNotification({
            title: data.message,
            message: (
              <pre className="max-h-full overflow-auto whitespace-pre-line">
                {clearErrorMessage(data.errors)}
              </pre>
            ),
            color: "red",
          });
        }
      },
    });

  const loginForm = useForm<{
    email: string;
    password: string;
  }>({
    initialValues: {
      email: "",
      password: "",
    },
    validate: yupResolver(
      Yup.object().shape({
        email: Yup.string().required("Email or Username is required"),
        password: Yup.string().min(
          5,
          "A minimum of 5 characters must be entered"
        ),
      })
    ),
  });

  const handleLogin = (values): void => userLogin(values);

  return (
    <form
      className={clsx(
        "flex flex-col w-full justify-items-stretch text-[#434E5B]",
        className
      )}
      onSubmit={loginForm.onSubmit(handleLogin)}
    >
      <TextInput
        id="login-username"
        autoComplete="username"
        label={<span className="text-[#434E5B]">E-mail or Username</span>}
        placeholder="Input your email or username here"
        size="md"
        name="email"
        {...loginForm.getInputProps("email")}
      />
      <PasswordInput
        id="login-password"
        autoComplete="current-password"
        label={<span className="text-[#434E5B]">Password</span>}
        placeholder="Type your password here"
        size="md"
        name="password"
        {...loginForm.getInputProps("password")}
      />

      {children}

      <Button
        fullWidth
        radius="md"
        classNames={{
          root: "bg-accent-90",
        }}
        className="font-semibold"
        type="submit"
        disabled={userLoading}
        loading={userLoading}
        loaderPosition="right"
        size="md"
      >
        Sign In
      </Button>
    </form>
  );
}
