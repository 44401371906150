import Link from "next/link";
import ECN from "@/public/company/ECN.svg";

import { ActionIcon, Button, clsx, Group, Text } from "@mantine/core";
import { FC, useEffect, useState } from "react";
import { Login, Notification } from "iconsax-react";
import { css } from "@emotion/css";

import { Drawer } from "@/components/drawer";
import { NotificationListType } from "@/sample/queries/notificationList";
import { queryVars, useCustomQuery } from "@/hooks/utils";
import { NotificationsDrawer } from "./notificationsDrawer";
import { NotificationIcon } from "@/components/notificationIcon";

export default function Navbar({ logoUrl, isDashboard = false }) {
  const { query, keys } = queryVars;
  
  const [drawer, setDrawer] = useState<{
    opened: boolean;
    component: null | FC<{
      notifications?: NotificationListType;
      notificationsLoading: boolean;
      handleCloseDrawer(): void;
    }>;
  }>({
    opened: false,
    component: null,
  });

  function handleCloseDrawer() {
    setDrawer({
      opened: false,
      component: null,
    });
  }

  const { data: notifications, isLoading: notificationsLoading } =
    useCustomQuery<NotificationListType>(
      keys.notificationList,
      query.notificationList
    );

  const [userBio, setUserBio] = useState({
    firstname: "",
    lastname: "",
    username: "",
  });

  useEffect(() => {
    const stringifiedUserBio = JSON.stringify(userBio);
    const sessionUserBio =
      localStorage.getItem("ecn.user.bio") ?? stringifiedUserBio;
    setUserBio(JSON.parse(sessionUserBio));
  }, []);

  const displayName = userBio?.firstname
    ? `${userBio?.firstname} ${userBio?.lastname}`
    : userBio.username;

  const notification = (
    <ActionIcon
      size={38}
      onClick={() =>
        setDrawer({
          opened: true,
          component: NotificationsDrawer,
        })
      }
      className={clsx(
        "relative grid group min-w-max hover:bg-gray-100 border transition border-[#e5e7eb] hover:border-primary-90 rounded-sm",
        css({
          "& > *": {
            gridArea: "1/1",
          },
        })
      )}
    >
      <Notification
        size="24"
        className="text-primary-70 group-hover:text-primary-90"
        variant="TwoTone"
      />
      <NotificationIcon sup>{notifications?.pending}</NotificationIcon>
    </ActionIcon>
  );

  const admin = (
    <Link href={logoUrl} passHref>
      <Button
        component="a"
        className="ecn-button"
        classNames={{
          label: "gap-2 h-5",
        }}
      >
        <Login className="self-end" size={18} />
        <p>
          Exit to <span className="font-bold">Administration</span>
        </p>
      </Button>
    </Link>
  );

  return (
    <nav
      className={clsx(
        "flex items-center justify-between w-full py-2 shadow-md z-[1]",
        isDashboard ? "px-2" : "px-5"
      )}
    >
      <Link href={logoUrl} passHref>
        <a>
          <ECN />
        </a>
      </Link>

      <Group>
        {isDashboard ? admin : notification}
        <Text className="px-4 py-2 text-sm font-light border rounded-sm">
          {displayName}
        </Text>
      </Group>

      <Drawer
        opened={drawer.opened}
        onClose={handleCloseDrawer}
        title="Notifications"
        classNames={{
          drawer: "flex flex-col",
          body: "flex-1 overflow-auto",
          header: "border-b px-4 py-3 mb-0 sticky z-10 top-0 bg-white",
        }}
      >
        {drawer.component ? (
          <drawer.component
            notifications={notifications}
            notificationsLoading={notificationsLoading}
            handleCloseDrawer={handleCloseDrawer}
          />
        ) : null}
      </Drawer>
    </nav>
  );
}
