import Head from "next/head";
import Navbar from "@/layout/partials/navBar";
import Sidebar from "@/layout/sideBar";

import { useLayoutEffect } from "react";
import { useLogout } from "@/hooks/utils";
import { hasCookie } from "cookies-next";

export default function AuthLayout({
  children,
  withNavbar = true,
  withSidebar = true,
}) {
  const logout = useLogout();

  useLayoutEffect(() => {
    if (!hasCookie("ecn.client.auth.token")) logout();
  }, []);

  return (
    <main className="flex flex-col items-stretch h-screen max-h-screen">
      <Head>
        <title>ECN</title>
        <meta name="description" content="ECN" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      {withNavbar && (
        <Navbar
          logoUrl={withSidebar ? "/" : "/client"}
          isDashboard={!withSidebar}
        />
      )}
      {withSidebar ? (
        <section className="flex flex-1 overflow-y-auto">
          <Sidebar />
          <div className="p-5 overflow-auto grow">{children}</div>
        </section>
      ) : (
        <>{children}</>
      )}
    </main>
  );
}
