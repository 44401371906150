import { Button, TextInput } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { useState } from "react";
import { useMutation } from "react-query";

import { Form } from "@/components";
import { queryVars } from "@/hooks/utils";
import { LoginType } from "@/sample/queries";
import { authPoster } from "@/utils/utils";

import { SuccessNotification } from "./successNotification";
import { ErrorNotification } from "./errorNotification";
import { onLoginSuccess } from "./onLoginSuccess";

import * as Yup from "yup";

async function loginFn(data: OTPForm) {
  return authPoster(queryVars.query.authenticateOtp, data)
    .then((data) => data as LoginType)
    .catch((response) => {
      showNotification({
        title: "Operation failed",
        message: response.message,
        color: "accent.8",
      });

      return undefined;
    });
}

interface IOTP {
  email: string;
  closeModal?(): void;
}

interface OTPForm {
  email: string;
  otp_code: string;
}

export function OTP({ email, closeModal }: IOTP) {
  const [showSuccessNotification, setShowSuccessNotification] = useState(false);
  const [showErrorNotification, setShowErrorNotification] = useState(false);

  const otpForm = useForm<OTPForm>({
    initialValues: {
      email,
      otp_code: "",
    },
    validate: yupResolver(
      Yup.object().shape({
        email: Yup.string().required("Email is required"),
        otp_code: Yup.string().length(6, "The code must be 6-digit in length"),
      })
    ),
  });

  const {
    mutate: login,
    isLoading: loginLoading,
    data: loginData,
  } = useMutation(loginFn, {
    onSuccess: onLoginSuccess({ setShowSuccessNotification, closeModal }),
  });

  const handleSubmit = (value) => login(value);

  return (
    <Form px={undefined} onSubmit={otpForm.onSubmit(handleSubmit)}>
      <TextInput
        id="login-otp"
        placeholder="Input the Authentication Code"
        size="md"
        name="otp"
        {...otpForm.getInputProps("otp_code")}
      />
      <Button
        fullWidth
        radius="md"
        classNames={{
          root: "bg-accent-90",
        }}
        className="font-semibold"
        type="submit"
        disabled={loginLoading}
        loading={loginLoading}
        loaderPosition="right"
        size="md"
      >
        Submit
      </Button>

      {showSuccessNotification && (
        <SuccessNotification
          close={setShowSuccessNotification}
          message={loginData?.message}
        />
      )}

      {showErrorNotification && (
        <ErrorNotification
          close={setShowErrorNotification}
          message={loginData?.message}
        />
      )}
    </Form>
  );
}
