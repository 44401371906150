import { ComponentType } from "react";
import AuthLayout from "./authLayout";

function withAuth(
  WrappedComponent: ComponentType<any>,
  options: {
    withNavbar?: boolean;
    withSidebar?: boolean;
  } = {}
) {
  return function AuthWrap() {
    if (typeof window === "undefined") return null;

    return (
      <AuthLayout {...options}>
        <WrappedComponent />
      </AuthLayout>
    );
  };
}

export default withAuth;
