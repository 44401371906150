import { Notification } from "@mantine/core";
import { TickSquare } from "iconsax-react";

export function SuccessNotification({ close, message }) {
  return (
    <Notification
      onClose={() => close(false)}
      icon={<TickSquare size="24" color="#37D67A" />}
      classNames={{
        icon: "!bg-transparent",
        root: "w-full",
      }}
    >
      {message}
    </Notification>
  );
}
